<!--
 * @Description: 页签样式等
 * @Author: 梁平贤
 * @Date: 2020/12/26 10:39
 -->
<template>
  <!--常规-->
  <!-- <en-tabs
    :list="list"
    class="multi-tab-control normal-tab-control"
    type="white"
    :fixedWidth="120"
    v-bind:value="value"
    v-on:input="tabChange"
    v-if="parseInt(type) === 4"
  >
  </en-tabs> -->

  <div class="multi-tab-container">
   <div class="multi-tabs" ref="multiTabsContainer">
        <div class="multi-tab-control normal-tab-control" ref="multiTabs" :style="{...boxBackground,transform:`translateX(-${translate}px)`}"  v-if="parseInt(type) === 4">
          <div
            v-for="(tab, index) in list"
            :key="tab.id"
            class="tab"
            :class="{ 'select-tab': tab.id === value }"
            :style="tab.id === value?selectStyle:defaultStyle"
            v-show="tab.show"
            @click="tabChange($event,tab.id, index)"
          >
            {{ tab.name }}
          </div>
      </div>
      <!--边框式-->
      <div class="multi-tab-control border-tab-control" ref="multiTabs" :style="{...boxBackground,transform:`translateX(-${translate}px)`}"  v-else-if="parseInt(type) === 5">
        <div
          v-for="(tab, index) in list"
          :key="tab.id"
          class="tab"
          :class="{ 'select-tab': tab.id === value }"
          :style="tab.id === value?selectStyle:defaultStyle"
          v-show="tab.show"
          @click="tabChange($event,tab.id, index)"
        >
          {{ tab.name }}
        </div>
      </div>
      <!--填充式-->
      <div class="multi-tab-control fill-tab-control" ref="multiTabs" :style="{...boxBackground,transform:`translateX(-${translate}px)`}" v-else-if="parseInt(type) === 6">
        <div
          v-for="(tab, index) in list"
          :key="tab.id"
          class="tab"
          :class="{ 'select-tab': tab.id === value }"
          :style="tab.id === value?selectStyle:defaultStyle"
          v-show="tab.show"
           @click="tabChange($event,tab.id, index)"
        >
          {{ tab.name }}
        </div>
      </div>
      <!--圆角式-->
      <div class="multi-tab-control radius-tab-control" ref="multiTabs" :style="{...boxBackground,transform:`translateX(-${translate}px)`}" v-else-if="parseInt(type) === 11">
        <div
          v-for="(tab, index) in list"
          :key="tab.id"
          class="tab"
          :class="{ 'select-tab': tab.id === value }"
          :style="tab.id === value?selectStyle:defaultStyle"
          v-show="tab.show"
           @click="tabChange($event,tab.id, index)"
        >
          {{ tab.name }}
        </div>
      </div>
   </div>
  <div class="icons" v-if="showIcons" :class="{'is-defaukt':parseInt(type) === 4}">
    <i class="el-icon-caret-left" @click="transleft" :class="{'icons-disabled': translate === 0}"></i>
    <i class="el-icon-caret-right" @click="transright" :class="{'icons-disabled': translate >= (tabsWidth - tabsContainerWidth)}"></i>
  </div>
  </div>
</template>

<script>
export default {
  name: "tab",
  props: {
    list: Array,
    value: String,
    type: String,
    businessData: Object,
    defaultStyle: Object,
    boxBackground: Object,
    selectStyle: Object
  },
  data() {
    return {
      showIcons: false,
      translate: 0,
      tabsWidth: 0,
      tabsContainerWidth: 0
    };
  },
  mounted() {
    this.businessData.field45change();
    console.log(this.selectStyle, this.defaultStyle, this.businessData, this.type, this.value, "valuevaluevaluevalue");
    this.getTabsWidth();
  },
  methods: {
    tabChange(e, value) {
      this.resetTranslate(e.target);
      this.$emit("input", value);
    },
    resetTranslate(dom) {
      const left = dom.offsetLeft;
      const width = dom.clientWidth;
      const leftPosition = left - this.translate;
      const rightPosition = this.tabsContainerWidth - (leftPosition + width);
      if (left < this.translate) {
        this.translate = left;
        return;
      }
      if (rightPosition < 0) {
        this.translate -= rightPosition;
      }
    },
    getTabsWidth() {
      const tabsWidth = this.$refs.multiTabs.clientWidth;
      let tabsContainerWidth = this.$refs.multiTabsContainer.clientWidth;
      this.tabsWidth = tabsWidth;
      if (tabsWidth > tabsContainerWidth) {
        this.showIcons = true;
      } else {
        this.showIcons = false;
      }
      this.$nextTick(() => {
        tabsContainerWidth = this.$refs.multiTabsContainer.clientWidth;
        this.tabsContainerWidth = tabsContainerWidth;
      });
    },
    transleft() {
      const min = 0;
      if (this.translate <= min) {
        this.translate = 0;
        return;
      }
      if (this.translate <= this.tabsWidth) {
        this.translate -= this.tabsContainerWidth;
      }
      if (this.translate <= min) {
        this.translate = 0;
      }
    },
    transright() {
      const max = this.tabsWidth - this.tabsContainerWidth;
      if (this.translate >= max) {
        this.translate = max;
        return;
      }
      if (this.translate < this.tabsWidth) {
         this.translate += this.tabsContainerWidth;
      }
      if (this.translate >= max) {
        this.translate = max + 40;
        console.log(this.translate);
      }
    }
  },
  watch: {
    list() {
      if (this.$refs.multiTabs) {
        console.log(22222, this.$refs.multiTabs);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.multi-tab-container{
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  .icons{
    width: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    color: #A9B5C6;
    font-size: 15px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f6fafd;
    height: 100%;
  }
  .icons-disabled{
    color:#A9B5C6;
    opacity: 0.8;
  }
}

.multi-tabs{
  flex:1;
  overflow: hidden;
  background-color: #f6fafd;
}

.multi-tab-control {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  float:left;
  transition-duration: 0.3s;
}
.tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 常规样式
.normal-tab-control {
  display: flex;
  border-top: 1px solid #dce5ec;
  border-bottom: 1px solid #dce5ec;
  width: 100%;
  .tab {
    cursor: default;
    min-width: 120px;
    text-align: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    height: 49px;
    color: #333333;
  }
  .select-tab {
    color: #3E90FE;
    border-bottom: 2px solid #3E90FE;
  }
}
.normal-tab-control,
.border-tab-control {
  background-color: #f6fafd;
}

.border-tab-control,
.fill-tab-control {
  display: flex;
  padding-left: 20px;
  .tab {
    cursor: default;
    min-width: 120px;
    text-align: center;
  }
}

.fill-tab-control {
  border-bottom: 1px solid #dce5ec;
  width: 100%;
  .tab {
    color: #333333;
    background: #eff5fc;
    border-radius: 6px 6px 0px 0px;
    margin-right: 6px;
  }
  .select-tab {
    color: #ffffff;
    background: #3E90FE;
  }
}
.radius-tab-control{
  display: flex;
  padding-left: 20px;
  align-items: center;
  .tab {
    cursor:pointer;
    min-width: 120px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 19px;
    border: 1px solid #DCE5EC;
    margin-right: 20px;
  }
  .select-tab {
    color: #ffffff;
    background: #3E90FE;
    border-color: #3E90FE;
  }
}
.border-tab-control {
  border-bottom: 1px solid #3E90FE;
  overflow: visible;
  .tab {
    cursor: default;
    color: #333333;
    border: none;
  }
  .select-tab {
    border: 1px solid #3E90FE;
    color: #3E90FE;
    margin-bottom: -1px;
    background-color: #eff5fc;
    border-bottom-color: transparent;
  }
}
.is-defaukt{
  border-top: 1px solid #f6fafd;
  border-bottom: 1px solid #f6fafd;
}
</style>

<!--
 * @Description:
 * @Author: 梁平贤
 * @Date: 2020/12/25 16:05
 -->
<template>
  <div class="instruction-container">
    <div class="title-box" :class="{isTitle:column.lineTypeCss.titleHide === '1'}" v-if="column.lineTypeCss.titleHide !== '1'">
      <span class="iconfont title-icon" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='2'">&#xe860;</span>
      <span class="line" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='3'"></span>
      <span class="dian" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='4'"></span>
      <span class="title" :style="fontStyle" >{{column.lineTypeCss.titleName}}</span>
    </div>
    <div class="bm-multi-tab-column">
      <tab
        :businessData="businessData"
        :list="column.showColumns"
        v-model="column.currentSelectTabId"
        :type="column.showStyle"
        :column="column"
        :defaultStyle="{
          fontSize:column.showStyleCss.unselectedFontsize,
          fontWeight: column.showStyleCss.fontWeight,
          color:column.showStyleCss.unselectedFontcolor,
          backgroundColor:column.showStyleCss.unselectedBackground,
          borderColor:column.showStyleCss.unselectedBackground,
        }"
        :selectStyle="{
          fontSize:column.showStyleCss.selectedFontsize,
          fontWeight:column.showStyleCss.selectedFontbold ? 700 : 400,
          color:column.showStyleCss.selectedFontcolor,
          backgroundColor:column.showStyleCss.selectedBackground,
          borderColor:column.showStyleCss.selectedBackground,
        }"
        :boxBackground="{
          backgroundColor:column.showStyleCss.boxBackground
        }"
      >
      </tab>

      <div
        class="bm-multi-tab-column-content"
        v-for="(columnData, ind) in column.showColumns"
        :key="columnData.id"

      >
        <!--这里为什么要搞的那么恶心呢, 是因为 -->
        <!--a.只有渲染在dom上el-form才会触发校验 -->
        <!--b.没用v-show实现,用的绑定class-height设置为0, el-form v-show和label-width:auto一起使用有bug-->
        <div :class="{ 'show-tab': columnData.id === column.currentSelectTabId }" class="tab-content">
          <div
            class="bm-multi-tab-column-row"
            v-for="(data, index) in columnData.values"
            :key="index"
          >
            <div class="bm-multi-tab-column-wrapper">
              <!--check-prop是指的这个字段的全路径,校验需要-->
              <business-column-rendering
                v-for="col in data"
                :key="col.uuid"
                :column="col"
                :businessData="businessData"
                :check-prop="checkProp + '.showColumns.' + ind + '.values.' + index + '.0'"
              >
              </business-column-rendering>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import tab from "./tab";

export default {
  name: "BMMultiTabColumn",
  components: { tab },
  props: {
    checkProp: {
      type: String,
      default: "column"
    },
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    // 控制渲染的
    fontStyle() {
      console.log(this.column.lineTypeCss.titleFontbold, "this.column.titleFontbold");
      return {
          "font-size": this.column.lineTypeCss.titleFontsize,
          color: this.column.lineTypeCss.titleFontcolor,
          "font-weight": this.column.lineTypeCss.titleFontbold === 1 ? 700 : 400
      };
    },
    iconStyle() {
      return {
        // background: this.column.lineTypeCss.titlePrecolor,
        color: this.column.lineTypeCss.titlePrecolor
      };
    }
  },
  methods: {
    // 根据数组返回数组内部是否都是不展示的字段,如果是,则隐藏
    judgeRowDisplay(row) {
      return row.some((column) => column.displayable);
    }
  },
  created() {
    console.log(this.column, "columncolumncolumn123");
  },
  beforeCreate() {
    this.$options.components.BusinessColumnRendering = () => import("../index");
  }
};
</script>

<style scoped lang="scss">
.bm-multi-tab-column {

  .tab-content {
    height: 0;
    overflow: hidden;

  }

  .show-tab {
    height: auto;
    border-bottom: 1px solid #DCE5EC;
  }

  .bm-multi-tab-column-row {
    padding-top: 10px;

    .bm-multi-tab-column-wrapper {
      padding: 0 5px 13px 5px;
      display: flex;
      flex-flow: row;
      overflow-x: auto;
      overflow-y: hidden;

      .business-column-rendering {
        margin-right: 10px;
      }

      .business-column-rendering:last-child {
        margin-right: 0;
      }
    }
  }
}
.instruction-container{
  // border: 1px solid $field-border-color;
  border-radius: 5px;
  .title-box{
    display: flex;
    align-items: center;
    // margin-bottom: 14px;
    .title-icon{
      color:#FFA32B;
      width: 16px;

    }
    .title{
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #1A1C1E;
    }
  }
  .isTitle{
    margin-bottom: 14px;
  }
  .sep-tit{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid $field-border-color;
    background: #F6FAFD;
    padding-left: 12px;
    font-weight: 600;
  }
  .sep-info{
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
  }
}
.line{
      width: 3px;
        height: 16px;
        background: #FFAD2C;
        border-radius: 1px;
        display: inline-block;
        vertical-align: middle;
  }
  .dian{
    width: 6px;
    height: 6px;
    background: #FFAD2C;
    border-radius: 3px;
}
</style>
